import React,  { Component, TimeUnit } from 'react';
import ReactDOM from "react-dom";
import LocalizedStrings from 'react-localization';
import { data } from './strings.js'
import wordcastlogo from './wordcastlogo_white.svg';
import robotemoji from './robot.gif';
//import arrowleft from './arrowleft.svg';
import './App.css';
import Distributions from "./Distributions.json";
import Boards from "./Boards.json";
import Changelog from "./Changelog.json";


import BloomFilter from "./bloomfilter.js";

import axios from 'axios';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();


let strings = new LocalizedStrings(data);


var CHANNEL = 'urn:x-cast:com.github.minosvasilias.wordcast';

var VERSIONCUTOFF = 11;
var RECEIVERVERSION = 9;



class SquareData{
  constructor(x, y, type) {
    this.x = x;
    this.y = y;
    this.type = type; //0 = NORMAL, 1 = LETTER X2, 2 = LETTER X3, 3 = LETTER X4, 4 = WORD X2, 5 = WORD X3, 6 = WORD X4
    this.selected = false;
    this.letter = null;
    this.tempLetter = null;
    this.isSelected = false;
  }
}
class Player{
  constructor(name, id, androidId) {
    this.name = name;
    this.id = id;
    this.androidId = androidId;
    this.score = 0;
    this.letters = new Array();
    this.turns = new Array();
    this.clientIndex = 0;
    this.isPassAlong = false;
  }
}

class Letter{
  constructor(value, score) {
    this.value = value;
    this.score = score;
    this.sourceIndex = 0;
  }
}



class App extends Component {
  constructor(props, context) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      players: {},
      gameState : 0,//0 = JOINING, 1 = RUNNING, 2 = FINISH
      cursorLocation: [7,7],
      curTurn: 0,
      turnCount: 0,
      noScoreCount: 0,
      isFirstLetterDown: false,
      langIndex: 0,
      boardIndex: 0,
      isDict: false,
      isLoadingDict: false,
      loadingProgress: 0.0,
      /*isPassAlong: false,*/
      isPro: false,
      locale: "en",

    };
    this.originTempSquare = null;
    this.originAxis = [-1,-1];

    /*this.specialCoords = [
      //LX2
      [[0,3],[0,11],[2,6],[2,8],
      [3,0],[3,7],[3,14],
      [6,6],[6,8],[6,12],[6,2],
      [7,3],[7,11],
      [8,6],[8,8],[8,12],[8,2],
      [11,0],[11,7],[11,14],
      [14,3],[14,11],[12,6],[12,8]],
      //LX3
      [[1,5],[1,9],
      [5,1],[5,5],[5,9],[5,13],
      [9,1],[9,5],[9,9],[9,13],
      [13,5],[13,9]],
      //WX2
      [[1,1],[2,2],[3,3],[4,4],
      [10,10],[11,11],[12,12],[13,13],
      [13,1],[12,2],[11,3],[10,4],
      [4,10],[3,11],[2,12],[1,13]],
      //WX3
      [[0,0],[0,7],[0,14],
      [7,0],[7,14],
      [14,0],[14,7],[14,14]],
      //START SQUARE
      [[7,7]]
    ];*/

    this.curDict = [null, null];
    this.turnLog = new Array();

    /*this.specialCoords = Boards[1].dist;
    this.setUpBoard();*/


    //this.setUpBloom();

    //ENABLE STRING FORMATTING
    if (!String.format) {
      String.format = function(format) {
        var args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function(match, number) {
          return typeof args[number] != 'undefined'
            ? args[number]
            : match
          ;
        });
      };
    }


    this.specialCoords = Boards[this.state.boardIndex].dist;
    this.setUpBoard();


    this.state.players = new Array();

    this.handleMessage = this.handleMessage.bind(this);
    this.moveCursor = this.moveCursor.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.addPlayer = this.addPlayer.bind(this);
    this.updateSenders = this.updateSenders.bind(this);

  }
  setUpBoard(){
    this.gameGrid = new Array(15);
    for(var i = 0; i < 15; i++){
      this.gameGrid[i] = new Array(15);
      for(var ii = 0; ii < 15; ii++){
        this.gameGrid[i][ii] =  new SquareData(i,ii,0);
      }
    }

    this.letterPool = new Array();

    for(var i = 0; i < this.specialCoords.length; i++){
      for(var ii = 0; ii < this.specialCoords[i].length; ii++){
        this.gameGrid[this.specialCoords[i][ii][1]][this.specialCoords[i][ii][0]].type = i+1;
      }
    }
  }
  makeid(length) {
   var result           = '';
   var characters       = 'öäüabcdefghijklmnopqrstuvwxyz';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
 }
 setUpBloom(){

      //FIRST, SET INDEX OF DISTRIBUTION
      var distIndex = 5;
      var testtruestring = "antagalla";//"betriebswirt";//"adfærdspåvirkning";//"amareleças";//"kanker"//alfeñicare";//SPANISH"alfeñicaré";
      var testfalsestring = "weghaun";//"blobsy";

      this.state.langIndex = distIndex;
      this.initializeLetterPool();
      var path = Distributions[this.state.langIndex].dictPath;

      //return;


   ////////////////////////////
       let dict;
       fetch(path)
       .then((r) => r.text())
       .then(text  => {
         //console.log(text);
         var splitDictRaw = text.split("\n");

         //IN CASE OF JSON
         splitDictRaw = JSON.parse(text);


         console.log(splitDictRaw);
         var splitDict = new Array();

         var bloom = new BloomFilter.BloomFilter(
           17387071,//GERMAN 29572728,//ENGLISH SOWPODS NEW 11548927 ,//DANISH 13403842,//PORTUGUESE 8831002,//DUTCH 7883400,//ENGLISH SOWPODS 6802848,//SPANISH 5975973,//ITALIAN 14898389,//FRENCH 5072222,//ENGLISH OLD 8868648 ,//GERMAN 32000000,//28755176,//25869786,//16284727,//8192 * 256,//32768 * 256,//32 * 256, // number of bits to allocate.
           19//GERMAN 30//ENGLISH SOWPODS NEW 30//DANISH 17//PORTUGUES 17//DUTCH 17//ENGLISH SOWPODS 18//SPANISH 17//ITALIAN 16//FRENCH 17//ENGLISH OLD 17//GERMAN11//10//9//6//16        // number of hash functions.
         );
         //var trie = new Trie();
         //console.log(JsSuffixTrie);
         //var trie = new JsSuffixTrie();

         // Add some elements to the filter.
         for(var i = 0; i < splitDictRaw.length; i++){
           var item = splitDictRaw[i].toLowerCase();



           //ADJUST THESE BASED ON SOURCE AND LANGUAGE

           //TRIM (CAREFUL, ONLY GERMAN AND ENGLISH AND DANSK SO FAR!)
           //item = item.substring(0, item.length-1);

           //REMOVE DIACRITICS ETC EXCEPT ñ
           var nIndex = item.indexOf("ñ"); //çñå
           item = item.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
           if(nIndex != -1){
            item = item.substr(0, nIndex) + "ñ" + item.substr(nIndex + 1);
           }





           var doAdd = true;
           /*for(var i = 0; i < item.length; i++){
             var char = item.charAt(i);
             if(!this.availableLetters.includes(char)){
               doAdd = false;
             }
           }*/

           if(doAdd){
             splitDict.push(item);
             //splitDict[i] = item;
             bloom.add(item);
           }
           //trie.add(item);
         }


         console.log("DICT LENGTH: " + splitDict.length);
         console.log(splitDict);

               // check contains method
               //console.log(trie.contains("arbeit"));  // true
               //console.log(trie.contains("fhqap")); // false


               var util = require('util');
               //var json = util.inspect(trie);

         //var array = [].slice.call(trie.children),
           //var  json = JSON.stringify(trie);
         //console.log(json);

         //TMP JSON SAVING
         /*var json = trie.toJSON();
         json = JSON.stringify(splitDict);
         console.log(json);
         var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(json);


         //TMP JSON SAVING
         var link = ReactDOM.findDOMNode(this.refs.Link1);
         link.href = dataUri;
         console.log("JSON READY TO DOWNLOAD");*/



         var falsecount = 0;
         var truecount = 0;
         for(var i = 0; i < splitDict.length; i++){
           var item = splitDict[i];
           var test = bloom.test(item);

           var gib = this.makeid(6);
           var test2 = bloom.test(gib);
           if(!test){
             falsecount += 1;
             //console.log(gib);
           }
           if(test2){
             truecount += 1;
             console.log(gib);
           }
         }
         /*if(splitDict.includes('jäger')){
           console.log("AAARBEIT");
         }*/
         //console.log(splitDict[101069].toLowerCase());
         console.log("FALSE NEGATIVES: " + falsecount);
         console.log("FALSE POSITIVES: " + truecount);
         var percentage1 = (falsecount / splitDict.length) * 100;
         var percentage2 = (truecount / splitDict.length) * 100;
         console.log("FALSE NEGATIVES PERCENT: " + percentage1);
         console.log("FALSE POSITIVES PERCENT: " + percentage2);
         // Test if an item is in our filter.
         // Returns true if an item is probably in the set,
         // or false if an item is definitely not in the set.

         var testtrue = bloom.test(testtruestring);
         var testfalse = bloom.test(testfalsestring);
         console.log("TESTTRUE = " + testtrue + ", TESTFALSE: " + testfalse);

         // Serialisation. Note that bloom.buckets may be a typed array,
         // so we convert to a normal array first.
         /*var array = [].slice.call(bloom.buckets),
             json = JSON.stringify(array);
         console.log(json);*/

         //ALTERNATIVE SERIALIZATION
         var blob = bloom.serialize();
         //json = BloomFilter.BloomFilter.bytesToString(blob);
         //console.log(enc.decode(blob));
         console.log(blob);


         let sb = BloomFilter.BloomFilter.bytesToString(blob);
         let bf = BloomFilter.BloomFilter.stringToBytes(sb);
         let bf2 = Buffer.from(sb,'utf16le');
         //bf = bf.swap16();
         bf2 = bf2.swap16();
         console.log(bf);





        var bloom = BloomFilter.BloomFilter.deserialize(bf2);
        var testtrue = bloom.test(testtruestring);
        var testfalse = bloom.test(testfalsestring);
        console.log("TESTTRUE = " + testtrue + ", TESTFALSE: " + testfalse);

         console.log("-----");


         /*var enc = new TextDecoder("utf-8");
         var encodedblob = enc.decode(blob);
         var enc2 = new TextEncoder("utf-8");
         var blob2 = enc2.encode(encodedblob);
         console.log(blob2);*/

         blob = blob.swap16();
         let string = blob.toString('utf16le');
         let buffer = Buffer.from(string, 'utf16le');
         console.log(buffer);
         buffer = buffer.swap16();
         console.log(buffer);

         // Function to download data to a file
         var data = bf;
         var filename = "test";
         var type = ".txt";
         var file = new Blob([data],
                    { type: "text/plain;charset=utf16le" }); //"octet/stream"
         //var file = new Blob([data], {type: type});
         if (window.navigator.msSaveOrOpenBlob) // IE10+
             window.navigator.msSaveOrOpenBlob(file, filename);
         else { // Others
             var a = document.createElement("a"),
                     url = URL.createObjectURL(file);
                     //url = URL.createObjectURL(blob);
             a.href = url;
             a.download = filename;
             document.body.appendChild(a);
             a.click();
             setTimeout(function() {
                 document.body.removeChild(a);
                 window.URL.revokeObjectURL(url);
             }, 0);
         }

         //console.log(json);


         var bloom = BloomFilter.BloomFilter.deserialize(buffer);
         var testtrue = bloom.test(testtruestring);
         var testfalse = bloom.test(testfalsestring);
         console.log("TESTTRUE = " + testtrue + ", TESTFALSE: " + testfalse);

         //return;
         var blobori = bloom.serialize();
         fetch('/spanish.bloom')
         //.then((r) => r.text())
         .then((r) => r.arrayBuffer())
         .then(text  => {
          //console.log(r);
          var uint8 = new Uint8Array(text);
          console.log(uint8);

          /*for(var x = 0; x < uint8.length; x++){
            if(uint8[x] != blobori[x]){
              console.log(x + ", " + blob[x] + " - " + blobori[x]);
            }
          }*/
          //var text = r.text();
          //var blob = r.blob();
           //var enc = new TextEncoder("utf-16");
           //var blob = enc.encode(json);
           var blob = Buffer.from(text, 'utf16le');
           //var blob = BloomFilter.BloomFilter.stringToBytes(text);
           //blob = blob.swap16();
           /*for(var x = 0; x < blob.length; x++){
             if(blob[x] != blobori[x]){
               console.log(x + ", " + blob[x] + " - " + blobori[x]);
             }
           }*/
           //var blob = BloomFilter.BloomFilter.stringToBytes(text);
             console.log(blob);
           var bloom = BloomFilter.BloomFilter.deserialize(blob);
           var testtrue = bloom.test(testtruestring);
           var testfalse = bloom.test(testfalsestring);
           console.log("TESTTRUE = " + testtrue + ", TESTFALSE: " + testfalse);

           var falsecount = 0;
           var truecount = 0;
           for(var i = 0; i < splitDict.length; i++){
             var item = splitDict[i];
             var test = bloom.test(item);

             var gib = this.makeid(6);
             var test2 = bloom.test(gib);
             if(!test){
               falsecount += 1;
               //console.log(item);
             }
             if(test2){
               truecount += 1;
               //console.log(gib);
             }
           }
           console.log("FALSE NEGATIVES FILE: " + falsecount);
           console.log("FALSE POSITIVES FILE: " + truecount);
           var percentage1 = (falsecount / splitDict.length) * 100;
           var percentage2 = (truecount / splitDict.length) * 100;
           console.log("FALSE NEGATIVES FILE PERCENT: " + percentage1);
           console.log("FALSE POSITIVES FILE PERCENT: " + percentage2);

         });
         //TMP JSON SAVING
         /*var dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(json);


         //TMP JSON SAVING
         var link = ReactDOM.findDOMNode(this.refs.Link1);
         link.href = dataUri;
         console.log("JSON READY TO DOWNLOAD");*/


         // Deserialisation. Note that the any array-like object is supported, but
         // this will be used directly, so you may wish to use a typed array for
         // performance.
         //var bloom = new BloomFilter(array, 16);*/
       });
 }










 _handleKeyDown = (e) => {

   switch(e.keyCode){
     //QUESTIONMARK FOR BLOOM
     case 219:
       this.setUpBloom();
       return;
     //CONSOLE KEY TO START
     case 220:
      this.state.gameState = 1;
      this.state.langIndex = 1;
      this.specialCoords = Boards[15].dist;
      this.setUpBoard();
      this.initializeLetterPool();
      this.addPlayer("Ajax", "1", "1", false, true, 99);
      this.addPlayer("Theseus", "2", "2", false, true, 99);
      this.addPlayer("Asterius", "3", "3", false, true, 99);
      this.addPlayer("Paris", "4", "4", false, true, 99);
      this.addPlayer("Achilles", "5", "5", false, true, 99);
      this.addPlayer("Agamemnon", "6", "6", false, true, 99);
      return;

     case 37:
       this.moveCursor("LEFT");
       return;
     case 38:
       this.moveCursor("UP");
       return;
     case 39:
       this.moveCursor("RIGHT");
       return;
     case 40:
       this.moveCursor("DOWN");
       return;
   }

   //RANDOM LETTER
   var rand = Math.floor(Math.random()*this.letterPool.length);
   var curSquare = this.gameGrid[this.state.cursorLocation[0]][this.state.cursorLocation[1]];
   curSquare.letter = this.letterPool[rand];
   this.letterPool.splice(rand, 1);
   this.setState({gameState: this.state.gameState});
   return;

   //CUSTOM LETTER
   console.log(e.key);
   for(var i = 0; i < this.letterPool.length; i++){
     var letter = this.letterPool[i];
     if(letter.value.toLowerCase() == e.key){
       var curSquare = this.gameGrid[this.state.cursorLocation[0]][this.state.cursorLocation[1]];
       curSquare.letter = letter;
       this.letterPool.splice(i, 1);

     }
   }
   this.setState({gameState: this.state.gameState});

 }


  componentDidMount(){
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);



    document.addEventListener("keydown", this._handleKeyDown);

    //PREVENT TIMEOUT
    window._setTimeout = window.setTimeout;
    window.setTimeout = function(a, b) {
        // disable setTimeout so chromecast won't kill us after 5 minutes...
    };

    var idleTime = 0;

    //LOAD CAST LOGIC

    this.ctx = window.cast.framework.CastReceiverContext.getInstance();
    var options = new window.cast.framework.CastReceiverOptions();
    //options.maxInactivity = 3600; //Development only
    options.disableIdleTimeout = true;

    //1H IDLE DISCONNECT
    var idleInterval = setInterval(() => {
        idleTime = idleTime + 1;
        if (idleTime > 60) { // 60 minutes
            this.ctx.stop();
        }
      }, 60000);



    options.customNamespaces = Object.assign({});
    options.customNamespaces[CHANNEL] = window.cast.framework.system.MessageType.JSON;

    var handleMessage = this.handleMessage;
    this.ctx.addCustomMessageListener(CHANNEL, function(customEvent) {
        idleTime = 0;
        handleMessage(customEvent.data, customEvent.senderId, customEvent.data.id);
    });
    this.ctx.addEventListener(window.cast.framework.system.EventType.SENDER_CONNECTED,
        event => {
        //CONNECT
    });
    this.ctx.addEventListener(window.cast.framework.system.EventType.SENDER_DISCONNECTED,
        event => {
        this.handleDisconnect(event.senderId);
    });
    this.ctx.start(options);

    //this.updateSenders();


  }

  debugOnScreen(message){
    //document.getElementById("content").innerHTML = message;
    console.log(message);
  }

  updateSenders(id){
    this.connectedSenders = this.ctx.getSenders();
    var debugstring = "";
    for(var i = 0; i < this.connectedSenders.length; i++){
      debugstring += this.connectedSenders[i].id + ", ";
    }
    this.debugOnScreen("MESSAGE RECEIVED FROM ID: " + id + " - CONNECTED IDS: " + debugstring);
  }

  handleDisconnect(id){
    for(var i = 0; i < this.state.players.length; i++){
      var player = this.state.players[i];
      if(player.id == id){
        if(this.state.gameState == 0){

          var players = this.findPlayers(player.androidId);
          for(var ii = 0; ii < players.length; ii++){
            var player = players[ii];
            var index = this.state.players.indexOf(player);
            this.state.players.splice(index, 1);
          }
          /*if(player.isPassAlongPlayer){
            var players = this.findPlayers(player.androidId);
            for(var ii = 0; ii < players.length; ii++){
              var player = players[ii];
              var index = this.state.players.indexOf(player);
              this.state.players.splice(index, 1);
            }
          }
          else{
            this.state.players.splice(i, 1);
          }*/
          this.sendToast(String.format(strings.playerdisconnect, player.name), undefined);
          this.setState({players: this.state.players})


          /*if(this.state.players.length == 1){
            this.allowPassAlong(true);
          }
          else if(this.state.players.length == 0){
            this.setState({isPassAlong: false});
            this.allowPassAlong(true);
          }*/
        }
      }
    }
  }

  handleMessage(msg, id, androidId){
    this.updateSenders(id);
    if(msg.msg == "STATE"){
        this.sendState(id, androidId);
    }
    if(msg.msg == "HURRYUP"){
        this.sendHurryUp();
    }
    if(msg.msg == "ENABLEPRO"){
        this.enablePro();
    }
    //LOBBY ONLY
    if(this.state.gameState == 0){
      if(msg.msg == "JOIN"){
          this.addPlayer(msg.name, id, androidId, msg.isPro, msg.isPassAlongPlayer, msg.versionCode, msg.locale);
      }
      if(msg.msg == "STARTGAME"){
          this.startGame(id, androidId, false, false);
      }
      if(msg.msg == "STARTGAMEPASSALONG"){
          this.startGame(id, androidId, true, false);
      }
      if(msg.msg == "STARTGAMEBOT"){
          this.startGame(id, androidId, true, true);
      }
      if(msg.msg == "LANGUAGE"){
          this.switchLanguage(msg.index);
      }
      if(msg.msg == "BOARD"){
          this.switchBoard(msg.index);
      }
      if(msg.msg == "DICT"){
          this.switchDict(msg.isDict, false);
      }
      if(msg.msg == "DICTLOADCANCEL"){
          this.cancelDictDownload();
      }
      if(msg.msg == "PASSALONG"){
          this.switchPassAlong(msg.isPassAlong, id, androidId);
      }
      if(msg.msg == "REMOVEPLAYER"){
          this.removePlayer(msg.index, id, androidId);
      }
    }
    if(msg.msg == "CANCEL"){
        this.cancelGame(androidId);
    }
    //CURRENT PLAYER ONLY
    if(this.state.players.length == 0 || this.state.gameState != 1)
      return;
    if(id != this.state.players[this.state.curTurn].id)
      return;
    if(msg.msg == "MOVE"){
        this.moveCursor(msg.dir);
    }
    if(msg.msg == "PLACE"){
      this.placeLetter(msg.index, msg.jokerValue, id);
    }
    if(msg.msg == "ENDTURN"){
        this.endTurn(id);
    }
    if(msg.msg == "SWAP"){
        this.swapLetters(msg.swapIndices, id);
    }
    if(msg.msg == "RESET"){
        this.resetTempLetters();
    }
    if(msg.msg == "SHUFFLE"){
        this.shuffleLetters(msg.shuffleId);
    }
  }

  findPlayer(androidId){
    var player = null;
    for(var i = 0; i < this.state.players.length; i++){
      if(this.state.players[i].androidId == androidId){
        player = this.state.players[i];
      }
    }
    return player;
  }

  findPlayers(androidId){
    var players = new Array();
    for(var i = 0; i < this.state.players.length; i++){
      if(this.state.players[i].androidId == androidId){
        var player = this.state.players[i];
        players.push(player);
      }
    }
    return players;
  }

  sendState(id, androidId){
    var json;

    if(this.state.isPro){
      this.enablePro();
    }

    var players = this.findPlayers(androidId);
    if(players.length > 0){
      if(players[0].isPassAlong){
        var json = {"msg":"PASSALONG","isPassAlong":true};
        this.ctx.sendCustomMessage(CHANNEL , id,  json);
      }
    }

    if(this.state.gameState == 0){
      if(players.length > 0){
        players[0].id = id;
        this.sendJoinSuccess(players[0]);
        return;
      }else{
        json = {"msg":"NAME"};
        this.ctx.sendCustomMessage(CHANNEL , id,  json);
        return;
      }
    }
    else if(this.state.gameState == 1){

      if(players.length > 0){
        //LET PLAYER REJOIN AND SEND INFO
        for(var i = 0; i < players.length; i++){
          players[i].id = id;

          //RESET LETTERS IF ITS REJOINING PLAYERS TURN IN ORDER TO AVOID UI MESS
          if(players[i].androidId == this.state.players[this.state.curTurn].androidId){
            this.resetTempLetters();
          }else{
            //this.sendPlayerLetters(player);
          }
          this.sendGameStarted(players[i]);
        }

        return;
      }
    }
    else if(this.state.gameState == 2){
      if(players.length > 0){
        for(var i = 0; i < players.length; i++){
          //LET PLAYER REJOIN AND SEND INFO
          players[i].id = id;
          this.endGame(players[i]);
          return;
        }
      }
    }
    json = {"msg":"GAMEONGOING"};
    this.ctx.sendCustomMessage(CHANNEL , id,  json);


  }

  sendToast(content, id){
    var json = {"msg":"TOAST","content":content};
    this.ctx.sendCustomMessage(CHANNEL , id,  json);
  }

  cancelGame(androidId){
    var player = this.findPlayer(androidId);
    if(this.state.gameState == 1){
      this.sendToast(String.format(strings.playercancel,player.name), undefined);
    }

    this.setUpBoard();
    this.setState({gameState: 0, cursorLocation: [7,7], curTurn: 0,
                  turnCount: 0, noScoreCount: 0, isFirstLetterDown: false});

    for(var i = 0; i < this.state.players.length; i++){
      this.state.players[i].score = 0;
      this.state.players[i].letters = new Array();
      this.state.players[i].turns = new Array();
      this.turnLog = new Array();
      this.sendState(this.state.players[i].id, this.state.players[i].androidId);
    }
  }

  cancelDictDownload(){
    if(this.cancel != null){
      this.cancel();
      delete this.curDict;
    }
  }

  switchDict(isDict, autoStart){
    if(this.state.gameState != 0){
      return;
    }
    this.setState({isDict: isDict});
    var json = {"msg":"DICT","isDict":isDict};
    this.ctx.sendCustomMessage(CHANNEL , undefined,  json);



    if(isDict){
      var langIndex = this.state.langIndex;
      if(this.curDict == undefined || this.curDict[0] != Distributions[langIndex].name){
        delete this.curDict;


        var dist = Distributions[langIndex];
        if(dist.dictStatus != 1 || this.state.isLoadingDict)
          return;

        for(var i = 0; i < this.state.players.length; i++){
          var json = {"msg":"DICTLOADSTART"};
          this.ctx.sendCustomMessage(CHANNEL , this.state.players[i].id,  json);
        }
        this.setState({isLoadingDict: true});
        var total = dist.dictSize;
        let cancel;
        axios.get(dist.dictPath, {
          responseType: 'arraybuffer',
          cancelToken: new CancelToken(function executor(c) {
            cancel = c;
          }),
          onDownloadProgress: (progressEvent) => {
            //this.state.players[0].name = progressEvent.loaded;
            var percent = progressEvent.loaded / total;
            this.setState({loadingProgress: percent});
          }
        })
        .then((response) => {
          //PARSE TXT INTO ARRAY
          //OLD NON BLOOM WAY
          /*var splitDict = response.data.split("\n");
          for(var i = 0; i < splitDict.length; i++){
            var item = splitDict[i].toLowerCase();
            var item = item.substring(0, item.length-1);
            splitDict[i] = item;
          }*/
          //BLOOM
          var data = response.data;
          var blob = Buffer.from(data, 'utf16le');
          var bloom = BloomFilter.BloomFilter.deserialize(blob);

          this.sendToast(strings.dictloaded, undefined);
          this.curDict = [Distributions[langIndex].name, bloom];
          this.setState({isLoadingDict: false, loadingProgress: 0.0});
          if(autoStart){
            this.startGame(this.state.players[0].id, this.state.players[0].androidId, true, true);
          }
          else{
            var json = {"msg":"DICTLOADEND"};
            this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
          }
        })
        .catch((err) => {
          this.sendToast(strings.dictfailed, undefined);
          this.setState({isLoadingDict: false, loadingProgress: 0.0});
          var json = {"msg":"DICTLOADEND"};
          this.ctx.sendCustomMessage(CHANNEL , undefined,  json);

          this.setState({isDict: false});
          var json = {"msg":"DICT","isDict":false};
          this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
        })
        this.cancel = cancel;
      }else{
        if(autoStart){
          this.startGame(this.state.players[0].id, this.state.players[0].androidId, true, true);
        }
      }

    }

  }

  switchLanguage(index){
    if(index != this.state.langIndex){
      this.setState({langIndex: index});
      var json = {"msg":"LANGUAGE","index":index};
      this.ctx.sendCustomMessage(CHANNEL , undefined,  json);

      this.setState({isDict: false});
      var json = {"msg":"DICT","isDict":false};
      this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
    }
  }

  switchBoard(index){
    if(index != this.state.boardIndex){
      this.setState({boardIndex: index});
      var json = {"msg":"BOARD","index":index};
      this.ctx.sendCustomMessage(CHANNEL , undefined,  json);

      this.specialCoords = Boards[index].dist;
      this.setUpBoard();
      this.setState({gameState: 1});
      this.setState({gameState: 0});
    }
  }

  switchPassAlong(isPassAlong, id, androidId){

    var player = null;
    var playersToRemove = new Array();
    var indicesToRemove = new Array();
    var passAlongIndex = 0;
    for(var i = 0; i < this.state.players.length; i++){
      if(this.state.players[i].androidId == androidId){
        player = this.state.players[i];
        player.isPassAlong = isPassAlong;
        if(!isPassAlong && passAlongIndex > 0){
          playersToRemove.push(player);
          indicesToRemove.push(passAlongIndex);
        }
        passAlongIndex++;
      }
    }
    for(var i = 0; i < playersToRemove.length; i++){
      var index = this.state.players.indexOf(playersToRemove[i]);
      this.state.players.splice(index,1);
    }
    this.setState({players: this.state.players});

    /*if(indicesToRemove.length > 0){
      var indicesJson =  JSON.stringify(indicesJson);
      var json = {"msg":"REMOVEPLAYER","indices":indicesToRemove};
      this.ctx.sendCustomMessage(CHANNEL , id,  json);
    }*/



    /*if(this.state.players.length == 1){
        this.setState({isPassAlong: isPassAlong});
        var json = {"msg":"PASSALONG","isPassAlong":isPassAlong};
        this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
    }else{
      var json = {"msg":"PASSALONG","isPassAlong":false};
      this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
      this.allowPassAlong(false);
    }*/
  }

  allowPassAlong(allow){
    var json = {"msg":"ALLOWPASSALONG","allow":allow};
    this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
  }

  sendHurryUp(){
    var json = {"msg":"HURRYUP"};
    var player = this.state.players[this.state.curTurn];
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
  }

  swapLetters(swapIndices, id){
    if(this.letterPool.length < 7){
      this.sendToast(strings.swapdeny, id);
      return;
    }
    if(swapIndices.length == 0){
      this.sendToast(strings.swapmin, id);
      return;
    }
    var player = this.state.players[this.state.curTurn];
    var offset = 0;
    var swapLetters = new Array();
    for(var i = 0; i < swapIndices.length; i++){
      var rand = Math.floor(Math.random()*this.letterPool.length);
      var letter = this.letterPool[rand];
      var prevLetter = player.letters[swapIndices[i]];
      swapLetters.push(prevLetter);

      player.letters[swapIndices[i]] = letter;
      this.letterPool.splice(rand, 1);
    }
    //ONLY ADD OLD LETTER TO POOL AFTER ALL NEW ONES ARE DRAWN
    for(var i = 0; i < swapLetters.length; i++){
      this.letterPool.push(swapLetters[i]);
    }

    this.setState({players: this.state.players});
    var lettersJson = JSON.stringify(player.letters);
    var json = {"msg":"LETTERSDRAW","letters":lettersJson,"shuffleId":""};
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
    this.endTurnNoScore(id);
  }

  endGame(targetPlayer){

    //CAREFUL NOT TO REPEAT SUBTRACTIONS ON REQUESTSTATE
    if(this.state.gameState != 2){
      //RESET CURSOR
      var oldSquare = this.gameGrid[this.state.cursorLocation[0]][this.state.cursorLocation[1]];
      oldSquare.isSelected = false;
      this.setState({cursorLocation:[7,7]});

      //SUBTRACT REMAINING LETTERS
      for(var i = 0; i < this.state.players.length; i++){
        this.subtractRemainingLetters(this.state.players[i]);
      }

    }
    this.setState({gameState: 2});

    //SORT BY SCORE
    var playersSorted = this.state.players.slice();
    playersSorted.sort(function(a, b){
        return a.score - b.score;
    });
    var scores = new Array();
    for(var i = 0; i < playersSorted.length; i++){
      scores.push({"name": playersSorted[i].name, "score":playersSorted[i].score});
      playersSorted[i].clientIndex = i;
    }
    for(var i = 0; i < playersSorted.length; i++){
      var player = playersSorted[i];
      if(targetPlayer != null && player.androidId == targetPlayer.androidId ||
        targetPlayer == null){
          var scoresString = JSON.stringify(scores);
          var json = {"msg":"GAMEENDED", "clientIndex":player.clientIndex, "scores":scoresString};
          this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
      }

    }
  }

  subtractRemainingLetters(player){
    var sum = 0;
    for(var i = 0; i < player.letters.length; i++){
      if(player.letters[i] != null){
        sum += player.letters[i].score;
      }
    }
    player.score = player.score - sum;
    if(player.score < 0){
      player.score = 0;
    }
  }

  endTurnNoScore(id){
    var noScoreCount = this.state.noScoreCount + 1;
    this.state.players[this.state.curTurn].turns.push([]);
    this.turnLog.push({"name":this.state.players[this.state.curTurn].name, "turnArray": []});
    this.setState({noScoreCount: noScoreCount});

    if(noScoreCount < 6){
      this.nextTurn();
    }else{
      this.endGame(null);
    }
  }

  endTurn(id){
    var tempSquares = this.getTempSquares();
    if(tempSquares.length == 0){
        this.isBotTurn = false;
        this.endTurnNoScore(id);
        return;

    }
    var bonusScore = 0;
    if(tempSquares.length == 7){
      bonusScore = 50;
    }
    //FIND WORDS TO SCORE
    var words = new Array();
    var wordStrings = new Array();
    var mainWord = null;
    var isConnected = false;
    for(var i = 0; i < tempSquares.length; i++){
      var adjSquares = this.getAdjSquares(tempSquares[i]);

      for(var ii = 0; ii < adjSquares.length; ii++){
        if(adjSquares[ii].letter != null || adjSquares[ii].tempLetter != null){
          if(adjSquares[ii].letter != null)
            isConnected = true;
          //TRACE TEMPLETTER WORD
          var word = this.getAdjWord(adjSquares[ii],tempSquares[i]).word;

          word.sort(function(a, b){
            if(a.x != b.x){
              return a.x - b.x;
            }
            else{
              return a.y - b.y;
            }
          });

          //CHECK FOR DUPLICATE
          var duplicateFound = false;
          for(var iii = 0; iii < words.length; iii++){
            if(word.length === words[iii].length && word.every(function(value, index) { return value === words[iii][index]})){
              duplicateFound = true;
            }
          }
          if(!duplicateFound){
            words.push(word);
            //FORM WORD STRING
            var wordString = "";
            for(var w = 0; w < word.length; w++){
              if(word[w].letter != null)
                wordString += word[w].letter.value;
              if(word[w].tempLetter != null)
                wordString += word[w].tempLetter.value;
            }
            wordStrings.push(wordString);
          }
          //mainWord = word;

        }
      }
    }
    if(!isConnected && this.state.isFirstLetterDown){
      this.resetTempLetters();
      this.sendToast(strings.tempconnect, id);
      return;
    }

    //FIRST TURN MAY JUST BE ONE LETTER

    if(tempSquares.length == 1 && !this.state.isFirstLetterDown){
      var word = new Array();
      word.push(tempSquares[0]);
      words.push(word);
    }
    if(!this.state.isFirstLetterDown && words.length > 0){
      var word = words[0];
      var isOnCenterSquare = false;
      for(var i = 0; i < word.length; i++){
        var square = word[i];
        if(square.x == 7 && square.y == 7){
          isOnCenterSquare = true;
        }
      }
      if(!isOnCenterSquare){
        this.resetTempLetters();
        this.sendToast(strings.tempcenter, id);
        return;
      }
    }


    //IF MORE THAN ONE WORD ON BOT TURN
    //CHANCE TO FAIL IN ORDER TO ENCOURAGE LESS CLUSTERING
    if(this.isBotTurn){
      if(wordStrings.length > 1){
        var rand = Math.floor(Math.random()*2);
        if(rand == 1){
          this.resetTempLetters();
          return;
        }
      }
    }


    //CHECK DICT IF ENABLED
    if(this.state.isDict){
      var check = this.checkWords(wordStrings);
      var illegalWords = check.illegalWords;
      if(illegalWords.length > 0){
        this.resetTempLetters();
        this.sendToast(check.illegalString, id);
        return;
      }
    }


  //SPECIAL RULES FOR SPANISH
  if(Distributions[this.state.langIndex].hasDoubles){
      for(var w = 0; w < words.length; w++){
        var word = words[w];
        var prevLetter = "";
        for(var i = 0; i < word.length; i++){
          var square = word[i];
          var curLetter = "";
          if(square.tempLetter != null){
            curLetter = square.tempLetter.value;
          }else{
            curLetter = square.letter.value;
          }
          if(i != 0){
            if(Distributions[this.state.langIndex].name == "Español"){
              if(prevLetter == "C" && curLetter == "H" ||
                 prevLetter == "L" && curLetter == "L" ||
                 prevLetter == "R" && curLetter == "R"){
                   this.resetTempLetters();
                   //this.sendToast("You must use the " + prevLetter + curLetter + " letter instead of " + prevLetter + " and " + curLetter + " separately!", id);
                   this.sendToast(String.format(strings.dualletters,prevLetter + curLetter, prevLetter, curLetter),id);
                   return;
              }
            }
            if(Distributions[this.state.langIndex].name == "Hrvatski"){
              if(prevLetter == "L" && curLetter == "J" ||
                 prevLetter == "N" && curLetter == "J" ||
                 prevLetter == "D" && curLetter == "Ž"){
                   this.resetTempLetters();
                   this.sendToast(String.format(strings.dualletters,prevLetter + curLetter, prevLetter, curLetter),id);
                   return;
              }
            }
          }
          prevLetter = curLetter;
        }
      }
    }

    //////////////////////////////////


    //SCORE WORDS
    var totalScore = 0;
    var turnLog = new Array();
    var debugstring = "";
    for(var w = 0; w < words.length; w++){
      debugstring += " --- WORD: ";
      var word = words[w];
      var score = 0;
      var wordMultiplier = 1;
      var wordString = "";
      var squareTypes = new Array();
      for(var i = 0; i < word.length; i++){
        var square = word[i];
        if(square.tempLetter != null){
          square.letter = square.tempLetter;
          square.tempLetter = null;
        }
        var letterScore = 0;
        if(square.type == 1){
          letterScore = square.letter.score * 2;
        }
        else if(square.type == 2){
          letterScore = square.letter.score * 3;
        }
        else if(square.type == 3){
          letterScore = square.letter.score * 4;
        }
        else{
          letterScore = square.letter.score;
        }
        score += letterScore;






        debugstring += square.letter.value + "("+letterScore+")-";
        wordString += square.letter.value;
        if(square.letter.value == ""){
          wordString += "_";
        }
        //ACCOUNT FOR PROBABLY IMPOSSIBLE SCENARIO OF BOTH WORD MULTIPLIERS USED
        if(square.type == 4 || square.type == 7){
          wordMultiplier = wordMultiplier * 2;
        }
        if(square.type == 5){
          wordMultiplier = wordMultiplier * 3;
        }
        if(square.type == 6){
          wordMultiplier = wordMultiplier * 4;
        }
        squareTypes.push(square.type);
      }
      score = score * wordMultiplier;
      totalScore += score;
      wordStrings.push(wordString);
      turnLog.push([wordString,score,squareTypes]);
      debugstring += " SCORE: " + score;
    }


    if(bonusScore != 0){
      turnLog.splice(0,0,["Bonus",50,[]]);
    }

    this.isBotTurn = false;

    //APPLY SCORE
    totalScore += bonusScore;
    debugstring += " --- BONUS: + " + bonusScore + " --- TOTAL: " + totalScore;


    //END NO SCORE STREAK
    this.setState({noScoreCount: 0});
    //IF VERY FIRST TURN IS A PASS, THIS MAKES SURE WE KNOW WHEN THE GAME REALLY STARTED
    this.state.isFirstLetterDown = true;
    this.state.players[this.state.curTurn].score += totalScore;

    //CHANGE TYPE OF USED SQUARES TO ZERO: SPECIAL TYPES CAN ONLY BE USED ON THE TURN THEY ARE COVERED
    for(var w = 0; w < words.length; w++){
      var word = words[w];
      for(var i = 0; i < word.length; i++){
        word[i].type = 0;
      }
    }

    //ADD TURN TO PLAYER HISTORY
    this.state.players[this.state.curTurn].turns.push(turnLog);
    this.turnLog.push({"name":this.state.players[this.state.curTurn].name, "turnArray": turnLog});


    // DEBUG
    var dstring = "";
    this.debugOnScreen("WORD SCORED: " + debugstring);

    this.drawLetters(this.state.players[this.state.curTurn]);

    //CHECK FOR GAME END
    var isEmpty = true;
    var playerLetters = this.state.players[this.state.curTurn].letters;
    for(var i = 0; i < playerLetters.length; i++){
      if(playerLetters[i] != null){
        isEmpty = false;
      }
    }
    if(isEmpty)
      this.endGame(null);

    //SWITCH TURNS
    this.nextTurn();
  }

  checkWords(words){
    var bloom = this.curDict[1];
    var illegalWords = new Array();
    var illegalString = "";
    for(var i = 0; i < words.length; i++){
      var isWord = bloom.test(words[i].toLowerCase());
      if(!isWord){
        if(illegalWords.length > 0){
          illegalString += ", ";
        }
        illegalWords.push(words[i]);
        illegalString += words[i];

      }
    }
    if(illegalWords.length == 1){
      //illegalString += " is an invalid word!"
      illegalString = String.format(strings.invalidword,illegalString);
    }else{
      //illegalString += " are invalid words!";
      illegalString = String.format(strings.invalidwords,illegalString);
    }
    return {illegalWords: illegalWords, illegalString: illegalString};

  }

  checkWordsOLD(words){
    var splitDict = this.curDict[1];
    var illegalWords = new Array();
    var illegalString = "";
    for(var i = 0; i < words.length; i++){
      var isWord = splitDict.includes(words[i].toLowerCase());
      if(!isWord){
        illegalWords.push(words[i]);
        illegalString += words[i];
      }
      if(i != words.length-1){
        illegalString += ", ";
      }
    }
    if(words.length == 1){
      illegalString += " is an invalid word!"
    }else{
      illegalString += " are invalid words!";
    }
    return {illegalWords: illegalWords, illegalString: illegalString};

  }

  doBotTurn(){
    this.isBotTurn = true;
    this.timer = setInterval(() => {
              this.getRandomWords();
              clearInterval(this.timer);
            }, 800);
  }


  getRandomWords(){
    var player = this.state.players[this.state.curTurn];
    var fixedSquares = this.getFixedSquares();

    //IF BOARD EMPTY, LET PLAYER DO FIRST TURN
    if(fixedSquares.length == 0){
      this.endTurn("BOT");
      return;
    }

    //CHANCE TO SKIP TURN
    rand = Math.floor(Math.random()*20);
    if(rand == 1){
        this.endTurn("BOT");
        return;
    }

    for(var i = 0; i < 1000; i++){
      if(!this.isBotTurn){
        return;
      }
      var letters = this.state.players[this.state.curTurn].letters;

      var rand = Math.floor(Math.random()*fixedSquares.length);

      var curSquare = fixedSquares[rand];
      var adjSquares = this.getAdjSquares(curSquare);
      rand = Math.floor(Math.random()*adjSquares.length);
      if(adjSquares[rand].letter == null){
        var space = new Array();
        //space.push(adjSquares[rand]);
        //space.push(curSquare);
        var dir = [adjSquares[rand].x - curSquare.x, adjSquares[rand].y - curSquare.y];
        var nextSquares = [curSquare,curSquare];
        var posEndFound = false;
        var negEndFound = false;
        while(!posEndFound || !negEndFound){
          //POSITIVE DIR
          var nextX = nextSquares[0].x+dir[0];
          var nextY = nextSquares[0].y+dir[1];
          if(nextX>-1 && nextX<15 && nextY>-1 && nextY<15 && !posEndFound){
            var nextSquare = this.gameGrid[nextX][nextY];
            if(nextSquare.tempLetter == null && nextSquare.letter == null){
              nextSquares[0] = nextSquare;
              space.push(nextSquare);
              //this.sendToast("SPACE: " + nextX + "," + nextY, undefined);
              if(space.length > 5){
                posEndFound = true;
              }

              rand = Math.floor(Math.random()*3);
              if(rand == 1){
                posEndFound = true;
              }
            }
            else{
              posEndFound = true;
            }
          }
          else{
            posEndFound = true;
          }
          //NEGATIVE DIR
          nextX = nextSquares[1].x+(dir[0]*-1);
          nextY = nextSquares[1].y+(dir[1]*-1);
          if(nextX>-1 && nextX<15 && nextY>-1 && nextY<15 && !negEndFound){
            var nextSquare = this.gameGrid[nextX][nextY];
            if(nextSquare.tempLetter == null && nextSquare.letter == null){
              nextSquares[1] = nextSquare;
              space.push(nextSquare);
              if(space.length > 5){
                negEndFound = true;
              }
              rand = Math.floor(Math.random()*3);
              if(rand == 1){
                negEndFound = true;
              }
            }
            else{
              negEndFound = true;
            }
          }
          else{
            negEndFound = true;
          }
        }
        //this.sendToast("SPACE FOUND: " + space.length, undefined);
        //this.sendToast("SPACE REP: " + i);

        for(var ii = 0; ii < space.length; ii++){
          var isNull = true;
          var tries = 0;
          while(isNull){
            tries++;
            if(tries > 1000){
              break;
            }
            rand = Math.floor(Math.random()*letters.length);
            if(letters[rand] != null){

              if(letters[rand].value == ""){
                var jokerrand = Math.floor(Math.random()*this.availableLetters.length);
                letters[rand].value = this.availableLetters[jokerrand];
              }
              space[ii].tempLetter = letters[rand];
              space[ii].tempLetter.sourceIndex = rand;
              letters[rand] = null;
              isNull = false;
            }
          }

        }

        this.setState({players: this.state.players});
        //this.resetTempLetters();
        //return;
        this.endTurn("BOT");



      }

    }
    this.endTurn("BOT");
  }




  nextTurn(){
    var nextTurn = this.state.curTurn + 1;
    var oldTurn = this.state.curTurn;
    if(nextTurn >= this.state.players.length){
      nextTurn = 0;
    }
    this.setState({turnCount: this.state.turnCount+1})
    this.setState({curTurn:nextTurn});
    for(var i = 0; i<this.state.players.length; i++){
      var isTurn = nextTurn == i;
      var turnName = this.state.players[nextTurn].name;

      //var turnLogJson = JSON.stringify(this.state.players[i].turns);
      var turnLogJson = JSON.stringify(this.turnLog);
      if(!this.state.players[i].isPassAlong || isTurn || !this.isDeviceTurn(this.state.players[i].androidId)){

        if(this.state.players[i].id == "BOT" && isTurn){
            this.doBotTurn();
        }

        var json = {"msg":"NEXTTURN", "isTurn":isTurn, "turnName":turnName, "turnLog":turnLogJson};
        this.ctx.sendCustomMessage(CHANNEL , this.state.players[i].id,  json);
        this.sendPlayerLetters(this.state.players[i]);
      }
    }
  }

  getTempSquares(){
    var tempSquares = new Array();
    for(var i = 0; i < this.gameGrid.length; i++){
      for(var ii = 0; ii < this.gameGrid[i].length; ii++){
        var square = this.gameGrid[i][ii];
        if(square.tempLetter != null){
          tempSquares.push(square);
        }
      }
    }
    return tempSquares;
  }

  getFixedSquares(){
    var fixedSquares = new Array();
    for(var i = 0; i < this.gameGrid.length; i++){
      for(var ii = 0; ii < this.gameGrid[i].length; ii++){
        var square = this.gameGrid[i][ii];
        if(square.letter != null){
          fixedSquares.push(square);
        }
      }
    }
    return fixedSquares;
  }

  getAdjSquares(square){
    var x = square.x;
    var y = square.y;
    var adjSquares = new Array();
    if(x+1<15)
      adjSquares.push(this.gameGrid[x+1][y]);
    if(x-1>-1)
      adjSquares.push(this.gameGrid[x-1][y]);
    if(y+1<15)
      adjSquares.push(this.gameGrid[x][y+1]);
    if(y-1>-1)
      adjSquares.push(this.gameGrid[x][y-1]);
    return adjSquares;
  }

  getAdjWord(adjSquare, curSquare){
    var dir = [adjSquare.x - curSquare.x, adjSquare.y - curSquare.y];
    var posEndFound = false;
    var negEndFound = false;
    var originFound = false;
    var word = new Array();
    word.push(curSquare);
    //START WITH CURRENT SQUARE FOR BOTH DIRECTIONS
    var nextSquares = [curSquare,curSquare];
    while(!posEndFound || !negEndFound){
      //POSITIVE DIR
      var nextX = nextSquares[0].x+dir[0];
      var nextY = nextSquares[0].y+dir[1];
      if(nextX>-1 && nextX<15 && nextY>-1 && nextY<15){
        var nextSquare = this.gameGrid[nextX][nextY];
        if(nextSquare.tempLetter != null || nextSquare.letter != null){
          nextSquares[0] = nextSquare;
          word.push(nextSquare);
          if(nextSquare.x == this.originTempSquare.x && nextSquare.y == this.originTempSquare.y){
            originFound = true;
          }
        }
        else{
          posEndFound = true;
        }
      }
      else{
        posEndFound = true;
      }
      //NEGATIVE DIR
      nextX = nextSquares[1].x+(dir[0]*-1);
      nextY = nextSquares[1].y+(dir[1]*-1);
      if(nextX>-1 && nextX<15 && nextY>-1 && nextY<15){
        var nextSquare = this.gameGrid[nextX][nextY];
        if(nextSquare.tempLetter != null || nextSquare.letter != null){
          nextSquares[1] = nextSquare;
          word.push(nextSquare);
          if(nextSquare.x == this.originTempSquare.x && nextSquare.y == this.originTempSquare.y){
            originFound = true;
          }
        }
        else{
          negEndFound = true;
        }
      }
      else{
        negEndFound = true;
      }
    }
    return {word : word, originFound : originFound}
  }

  placeLetter(index, jokerValue, id){
    var curSquare = this.gameGrid[this.state.cursorLocation[0]][this.state.cursorLocation[1]];
    var curLetter = this.state.players[this.state.curTurn].letters[index];
    var isLegal = true;
    if(curSquare.letter != null || curSquare.tempLetter != null || curLetter == null){
      isLegal = false;
      this.sendToast(strings.squareinuse, id);
    }
    var tempSquares = this.getTempSquares();
    if(tempSquares.length == 0){
      this.originTempSquare = curSquare;
      this.debugOnScreen("ORIGINTEMPSQUARE: " + this.originTempSquare.x + ","+this.originTempSquare.y);

    }
    else if(tempSquares.length > 0){
      var adjSquares = this.getAdjSquares(curSquare);
      var originFound = false;

      for(var ii = 0; ii < adjSquares.length; ii++){
        if(adjSquares[ii].letter != null || adjSquares[ii].tempLetter != null){
          if(this.getAdjWord(adjSquares[ii], curSquare).originFound)
            originFound = true;

        }
      }
      if(!originFound){
        isLegal = false;
        if(tempSquares.length > 1){
          if(this.originAxis[0] != -1 && curSquare.x != this.originAxis[0] ||
             this.originAxis[1] != -1 && curSquare.y != this.originAxis[1] ){
            this.sendToast(strings.tempstraightline, id);
          }else{
            this.sendToast(strings.tempconnected, id);
          }
        }else{
          this.sendToast(strings.tempconnected, id);
        }
      }
      else{

        if(tempSquares.length == 1){
          if(this.originTempSquare.x == curSquare.x){
            this.originAxis = [curSquare.x,-1];
          }else{
            this.originAxis = [-1,curSquare.y];
          }
        }
        if(tempSquares.length > 1){
          if(this.originAxis[0] != -1 && curSquare.x != this.originAxis[0] ||
             this.originAxis[1] != -1 && curSquare.y != this.originAxis[1] ){
            isLegal = false;
            this.sendToast(strings.tempstraightline, id);
          }
        }
        this.debugOnScreen("ORIGIN FOUND! AXIS: " + this.originAxis[0] +"," +this.originAxis[1]);

      }
    }


    if(isLegal){
      if(curLetter.value == ""){
        curLetter.value = jokerValue;
      }
      curSquare.tempLetter = curLetter;
      curSquare.tempLetter.sourceIndex = index;
      this.state.players[this.state.curTurn].letters[index] = null;
      this.setState({players: this.state.players});
      var json = {"msg":"PLACED","index":index};
      this.ctx.sendCustomMessage(CHANNEL , id,  json);
    }
  }

  resetTempLetters(){
    var tempSquares = this.getTempSquares(false);
    var player = this.state.players[this.state.curTurn];
    var offset = 0;
    for(var i = 0; i < tempSquares.length; i++){
      /*while(player.letters[i+offset] != null){
        offset += 1;
      }*/
      if(tempSquares[i].tempLetter.score == 0){
        tempSquares[i].tempLetter.value = "";
      }
      //player.letters[i+offset] = tempSquares[i].tempLetter;
      player.letters[tempSquares[i].tempLetter.sourceIndex] = tempSquares[i].tempLetter;
      tempSquares[i].tempLetter = null;
    }
    this.setState({players: this.state.players});
    this.sendPlayerLetters(player);
  }

  shuffleLetters(shuffleId){
    var tempSquares = this.getTempSquares(false);
    if (tempSquares.length != 0){
      return;
    }
    var player = this.state.players[this.state.curTurn];

    for (var i = player.letters.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [player.letters[i], player.letters[j]] = [player.letters[j], player.letters[i]];
    }

    this.setState({players: this.state.players});
    this.sendPlayerLettersShuffle(player,shuffleId);
  }

  moveCursor(dir){
    var oldSquare = this.gameGrid[this.state.cursorLocation[0]][this.state.cursorLocation[1]];
    var x = this.state.cursorLocation[0];
    var y = this.state.cursorLocation[1];
    this.debugOnScreen("MOVING CURSOR " + dir);
    switch(dir){
      case "UP":
        y -= 1;
        break;
      case "DOWN":
        y += 1;
        break;
      case "LEFT":
        x -= 1;
        break;
      case "RIGHT":
        x += 1;
        break;
      default:
        break;
    }
    if(x > -1 && x < 15 && y > -1 && y < 15){
      var curSquare = this.gameGrid[x][y];
      oldSquare.isSelected = false;
      curSquare.isSelected = true;
      this.setState({cursorLocation:[x,y]});
      //this.debugOnScreen("MOVED CURSOR TO "+ this.state.cursorLocation[0] + "," + this.state.cursorLocation[1]);
    }

  }

  startGame(id, androidId, forcestart, addbot){
    var players = this.findPlayers(androidId);
    if(players[0].isPassAlong && !forcestart){
      var playerNames = new Array();
      for(var i = 0; i < players.length; i++){
        playerNames.push (players[i].name);
      }
      var playerNamesJson = JSON.stringify(playerNames);

      var json = {"msg":"CHOOSEPLAYERS", "playerNames":playerNamesJson};
      this.ctx.sendCustomMessage(CHANNEL , id,  json);
      return;
    }
    //SOLO PLAY
    if(this.state.players.length == 1){
      var isBot = Distributions[this.state.langIndex].dictStatus == 1;

      if(!forcestart){
        var json = {"msg":"SOLOALERT", "isBot":isBot};
        this.ctx.sendCustomMessage(CHANNEL , id,  json);
        return;
      }

      if(isBot && addbot){
        if(this.state.isDict){
          this.addPlayer("🤖", "BOT", "BOT", false, false, 99999, undefined);
        }
        else{
          this.switchDict(true, true);
          return;
        }
      }
    }else{
      if(this.state.players[1].id != "BOT"){
        if(this.state.isDict &&  !this.state.isPro){
          this.switchDict(false, false);
        }
      }
    }
    /*if(this.state.isPassAlong && this.state.players.length < 2){
      var json = {"msg":"CHOOSEPLAYERS", "name":player.name};
      this.ctx.sendCustomMessage(CHANNEL , id,  json);
      return;
    }*/
    this.debugOnScreen("GAME STARTED");

    this.specialCoords = Boards[this.state.boardIndex].dist;
    this.setUpBoard();

    this.initializeLetterPool();
    this.gameGrid[7][7].isSelected = true;
    this.setState({cursorLocation:[7,7]});

    for(var i = 0; i<this.state.players.length; i++){
      var player = this.state.players[i];
      this.drawLettersInit(player);
      this.sendGameStarted(player);
    }
    this.setState({gameState:1});
  }

  sendGameStarted(player){
    var playerIndex = -1;
    playerIndex = this.state.players.indexOf(player);
    /*for(var i = 0; i < this.state.players.length; i++){
      if(this.state.players[i].androidId == player.androidId){
        playerIndex = i;
      }
    }*/
    var isTurn = this.state.curTurn == playerIndex;
    var turnName = this.state.players[this.state.curTurn].name;
    //var turnLogJson = JSON.stringify(this.state.players[playerIndex].turns);
    var turnLogJson = JSON.stringify(this.turnLog);

    if(!player.isPassAlong || isTurn || !this.isDeviceTurn(player.androidId)){
      this.sendPlayerLetters(player);
      var json = {"msg":"GAMESTARTED", "isTurn":isTurn, "turnName":turnName, "letters":this.availableLetters, "turnLog":turnLogJson};
      this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
    }
  }

  isDeviceTurn(androidId){
    var players = this.findPlayers(androidId);
    var isDeviceTurn = false;
    for(var i = 0; i < players.length; i++){
      var isTurn = this.state.curTurn == this.state.players.indexOf(players[i]);
      if(isTurn){
        isDeviceTurn = true;
      }
    }
    return isDeviceTurn;
  }

  initializeLetterPool(){
    var letters = Distributions[this.state.langIndex].dist;
    this.letterPool = new Array();
    this.availableLetters = new Array();
    for(var i = 0; i < letters.length; i++){
      if(letters[i].length > 0){
        for(var ii = 0; ii < letters[i].length; ii++){
          if(!this.availableLetters.includes(letters[i][ii][0]) && letters[i][ii][0] != ""){
            this.availableLetters.push(letters[i][ii][0]);
          }
          for(var iii = 0; iii < letters[i][ii][1]; iii++){
            var letter = new Letter(letters[i][ii][0],i);
            this.letterPool.push(letter);
          }
        }
      }
    }
    this.availableLetters.sort();
    console.log(this.availableLetters);
  }

  sendPlayerLetters(player){
    var lettersJson = JSON.stringify(player.letters);
    var json = {"msg":"LETTERSDRAW","letters":lettersJson,"shuffleId":""};
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
  }
  sendPlayerLettersShuffle(player, shuffleId){
    var lettersJson = JSON.stringify(player.letters);
    var json = {"msg":"LETTERSDRAW","letters":lettersJson,"shuffleId":shuffleId};
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
  }
  drawLettersInit(player){
    var diff = 7 - player.letters.length;
    for(var i = 0; i < diff; i++){
      var rand = Math.floor(Math.random()*this.letterPool.length);
      var letter = this.letterPool[rand];
      this.letterPool.splice(rand, 1);
      player.letters.push(letter);
    }
    //this.sendPlayerLetters(player);
  }
  drawLetters(player){
    for(var i = 0; i < 7; i++){
      if(player.letters[i] == null){
        if(this.letterPool.length != 0){
          var rand = Math.floor(Math.random()*this.letterPool.length);
          var letter = this.letterPool[rand];
          player.letters[i] = letter;
          this.letterPool.splice(rand, 1);
        }
      }
    }
    //this.sendPlayerLetters(player);
  }

  addPlayer(name, id, androidId, isPro, isPassAlongPlayer, versionCode, locale){
    if(versionCode < VERSIONCUTOFF){
      var json = {"msg":"OUTOFDATEALERT"};
      this.ctx.sendCustomMessage(CHANNEL , id,  json);
      //this.sendToast("Your app is out of date. Please update Wordcast in the Play Store.", id);
      return;
    }

    if(!isPassAlongPlayer){
      /*if(this.state.isPassAlong){
        this.sendToast("You may not join a game that is in Pass-Along mode!", id)
        return;
      }*/
      var json = {"msg":"PASSALONG","isPassAlong":false};
      this.ctx.sendCustomMessage(CHANNEL , id,  json);
      for(var i = 0; i < this.state.players.length; i++){
        if(this.state.players[i].androidId == androidId){
          return;
        }
      }
    }

    if(this.state.players.length > 4){
      this.sendToast(String.format(strings.maxplayers,this.state.players.length), id);
      return;
    }
    if(this.state.players.length == 0){
      if(locale != null && locale != undefined){
        this.setState({locale: locale});
        strings.setLanguage(locale);
      }
    }

    var newPlayers = this.state.players.slice();
    var newPlayer = new Player(name, id, androidId);
    newPlayer.isPassAlong = isPassAlongPlayer;
    newPlayers.push(newPlayer);
    this.setState({players:newPlayers})
    this.debugOnScreen("NEW PLAYER: " + name + ", ID: " + id);

    if(isPro){
      this.enablePro();
    }

    if(!isPassAlongPlayer){
      this.sendJoinSuccess(newPlayer);
    }
    else{
      this.sendPassAlongJoinSuccess(newPlayer);
    }
  }

  removePlayer(index, id, androidId){
    var players = this.findPlayers(androidId);
    if(players.length < index + 1){
      return;
    }
    var player = players[index];
    var removeIndex = this.state.players.indexOf(player);

    this.state.players.splice(removeIndex, 1);
    this.setState({players: this.state.players})

    var indices = new Array();
    indices.push(index);
    var indicesJson = JSON.stringify(indices);
    var json = {"msg":"REMOVEPLAYER", "indices": indicesJson};
    this.ctx.sendCustomMessage(CHANNEL , id,  json);
  }

  enablePro(){
    this.setState({isPro:true});
    var json = {"msg":"ISPRO"};
    this.ctx.sendCustomMessage(CHANNEL , undefined,  json);
  }


  sendJoinSuccess(player){
    //GET AVAILABLE LANGUAGES
    var langs = new Array();
    for(var i = 0; i < Distributions.length; i++){
      langs.push({name:Distributions[i].name, dictStatus:Distributions[i].dictStatus, dictInfo:Distributions[i].dictInfo});
    }
    var boards = new Array();
    for(var i = 0; i < Boards.length; i++){
      boards.push(Boards[i].name);
    }
    var langsJson = JSON.stringify(langs);
    var boardsJson = JSON.stringify(boards);

    //RESET BOT CHANGES
    if(this.state.players.length > 1 && player.id != "BOT"){
      if(this.state.isLoadingDict && !this.state.isPro){
        this.cancelDictDownload();
      }
      //PROBABLY NOT NEEDED
      if(this.state.players[1].id == "BOT"){
        this.switchDict(false, false);
        this.state.players.splice(1, 1);
        this.setState({players: this.state.players});
      }
    }


    var json = {"msg":"JOINSUCCESS", "langs":langsJson, "langIndex":this.state.langIndex, "boards": boardsJson, "boardIndex": this.state.boardIndex, "isDict":this.state.isDict, "isLoadingDict":this.state.isLoadingDict, "isPro":this.state.isPro};
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);


    //UPDATEALERT
    var json = {"msg":"UPDATEALERT", "receiverVersion":RECEIVERVERSION, "alertTitle":Changelog[RECEIVERVERSION].alertTitle,  "alert": Changelog[RECEIVERVERSION].alert};
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);


  }

  sendPassAlongJoinSuccess(player){
    var json = {"msg":"ADDPLAYER", "name":player.name};
    this.ctx.sendCustomMessage(CHANNEL , player.id,  json);
  }


  updateDimensions(){
     this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  renderSquareLine(line,i){
    return(
      <div className="line">
        {line.map((square, i) => this.renderSquare(square, i))}
      </div>
    )
  }
  renderSquare(square, i){
    var isSelected = false;
    isSelected = square.isSelected;
    /*if(square.x == cursorLocation[0] && square.y == cursorLocation[1]){
      isSelected = true;
    }*/
    return(
      <Square square={square} i={i} isSelected={isSelected} strings={strings}></Square>
    )
  }

  renderLeftInfo(){
    return(
      <div className="leftinfo">
        <div className="title">
          {strings.remaining}
        </div>
        <div className="poolcount">
          {this.letterPool.length}
        </div>
      </div>
    );
  }
  renderRightInfo(isLobby){
    var classname = "rightinfo";
    if(isLobby)
      classname = "lobbyinfo";

    var winstring = "";
    var winstringbot = "";
    if(this.state.gameState == 2){
      var max = 0;
      var maxindex = new Array();
      for(var i = 0; i < this.state.players.length; i++){
        var player = this.state.players[i];
        if(player.score > max){
          max = player.score;
          maxindex = new Array();
          maxindex.push(i);
        }
        else if(player.score == max){
          maxindex.push(i);
        }
      }

      if(maxindex.length == 1){
        winstring = String.format(strings.winwarning, this.state.players[maxindex[0]].name);

        if(this.state.players[maxindex[0]].name == "🤖"){
          winstring = "Bot wins!"
        }
      }
      else{
        winstring = strings.drawwarning;
        for(var i = 0; i < maxindex.length; i++){
          if(this.state.players[maxindex[i]].name == "🤖"){
            winstring += "Bot";
          }else{
            winstring += this.state.players[maxindex[i]].name;
          }
          if(i == maxindex.length -1){
            winstring += "!";
          }else{
            winstring += strings.drawand;
          }
        }

      }
    }
    var noScoreString = "";
    if(this.state.noScoreCount > 1 && this.state.noScoreCount < 6){
      noScoreString = String.format(strings.noscorewarning,this.state.noScoreCount);
    }
    var playersTitle = strings.playerstitle;
    if(this.state.players.length == 0)
      playersTitle = strings.waiting;
    return(
      <div className={classname}>
        <div className="title">
          {playersTitle}
        </div>
        <div className="players">
          {this.state.players.map((player, i) => this.renderPlayer(player, i, isLobby))}
        </div>
        <div className="wintext">
          {winstringbot}{winstring}
        </div>
        <div className="warning">
          {noScoreString}
        </div>
      </div>
    );

  }
  renderPlayer(player,i, isLobby){
    var isBorder = "";
    if(i < this.state.players.length-1)
      isBorder = " border";
    var isActive = "";
    if(i == this.state.curTurn)
      isActive = "active";
    var name = player.name;
    if(player.name == "🤖"){
      name =  <img
  className="emoji"
  src={robotemoji}/>
    }
    if(isLobby){
      return(
        <div className={"player lobby"}>
          {name}
        </div>)
    }else{
      return(
        <div className={"player" + isBorder}>
          <div className={isActive}/>
          <div className="playername">
          {name}
          </div>
          <div className="score">
          {player.score}
          </div>
        </div>
      )
    }
  }

  renderProgressBar(progress){
    //PAD PROGRESS A BIT TO UPDATE BEFORE PROCESSING FREEZE
    var loadingProgress = this.state.loadingProgress + 0.05;
    if(loadingProgress > 1.0)
      loadingProgress = 1.0;

    var width = loadingProgress * 200;
    var language = Distributions[this.state.langIndex].name;
    var progresstext = String.format(strings.dictloading,language);
    if(loadingProgress == 1.0){
      progresstext = String.format(strings.dictprocessing,language);
    }
    return(
      <div className="progress">
        <div className="progressbar" style={{ 'width': width}}></div>
        <div className="progresstext">{progresstext}</div>
        <div className="progressbar" style={{ 'width': width}}></div>
      </div>
    )
  }


  render() {
    var scale = scale = Math.min(this.state.width/360, this.state.height/360);

    if(this.state.gameState > 0){
      return (
        <div className="App">
          <div className="box" >
            <div className="container" style={{ 'transform': 'scale(' + scale + ')'}}>
              <div className="grid">
                <div className="fields">
                  {this.gameGrid.map((line, i) => this.renderSquareLine(line, i))}

                </div>
              </div>
              {this.renderRightInfo(false)}
              {this.renderLeftInfo()}
            </div>
          </div>
          <img className="smalllogo" src={wordcastlogo} />
        </div>
      );
    }else{
      if(this.state.isLoadingDict){
        return (
          <div className="App">
            <div className="box" >
            <div className="container" style={{ 'transform': 'scale(' + scale + ')'}}>
              <img className="logo" src={wordcastlogo} />
              {this.renderProgressBar(this.state.loadingProgress)}
              </div>
            </div>
          </div>
        );
      }else{
        return (
          <div className="App">
            <div className="box" >
              <div className="container" style={{ 'transform': 'scale(' + scale + ')'}}>
                <img className="logo" src={wordcastlogo} />
                {this.renderRightInfo(true)}
                <div className="boardpreview">
                  <div className="title">
                    {strings.board}
                  </div>
                  <div className="boardtitle">
                    {Boards[this.state.boardIndex].name}
                  </div>
                  <div className="boardscale">
                    {this.gameGrid.map((line, i) => this.renderSquareLine(line, i))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export class Square extends React.Component {
  constructor(props) {
    super(props);
    this.state={
    }
    this.classname = "square ";
    this.multiplierText = "";
    this.multiplierNum = "";

    if(props.square.type == 1){
      this.classname += "letterx2";
      this.multiplierText = props.strings.lettertitle;
      this.multiplierNum = "x2";
    }
    if(props.square.type == 2){
      this.classname += "letterx3";
      this.multiplierText = props.strings.lettertitle;
      this.multiplierNum = "x3";
    }
    if(props.square.type == 3){
      this.classname += "letterx4";
      this.multiplierText = props.strings.lettertitle;
      this.multiplierNum = "x4";
    }
    if(props.square.type == 4){
      this.classname += "wordx2";
      this.multiplierText = props.strings.wordtitle;
      this.multiplierNum = "x2";
    }
    if(props.square.type == 5){
      this.classname += "wordx3";
      this.multiplierText = props.strings.wordtitle;
      this.multiplierNum = "x3";
    }
    if(props.square.type == 6){
      this.classname += "wordx4";
      this.multiplierText = props.strings.wordtitle;
      this.multiplierNum = "x4";
    }
    /*if(props.square.type == 7){
      this.classname += "startsquare";
      this.multiplierText = "Word";
      this.multiplierNum = "x2";
    }*/
    if(props.square.x == 7 && props.square.y == 7){
        this.classname = "square startsquare";
    }

    this.selected = false;
    this.isLetter = false;
    this.isTempLetter = false;


  }

  shouldComponentUpdate(nextProps) {
    var oldSelect = this.selected;
    this.selected = nextProps.isSelected;
    if(oldSelect != this.selected){
      return true;
    }


    if(nextProps.square.letter != null && !this.isLetter){
      this.isLetter = true;
      return true;
    }
    var isTempLetter = nextProps.square.tempLetter != null;
    if(isTempLetter != this.isTempLetter){
      this.isTempLetter = isTempLetter;
      return true;
    }

    return false;

  }


  render(){
    this.selected = this.props.isSelected;
    var value = "";
    var score = "";
    var classname = this.classname;
    var multiplierclassname = "";
    var letterStyle = "";
    if(this.props.square.letter != null){
      classname = "lettercontainer";
      value = this.props.square.letter.value;
      score = this.props.square.letter.score;
      multiplierclassname = " hide";
      if(this.props.square.letter.score == 0)
        letterStyle = " fade";
    }
    else if(this.props.square.tempLetter != null){
      classname = "lettercontainertemp";
      value = this.props.square.tempLetter.value;
      score = this.props.square.tempLetter.score;
      multiplierclassname = " hide";
      if(this.props.square.tempLetter.score == 0)
        letterStyle = " fade";
    }

    if(this.selected){
      return(
        <div className={classname}>
          <div className={classname + " selected"} style={{ 'marginLeft': this.margin}}>
            <div className={"multipliertext" + multiplierclassname}>{this.multiplierText}</div>
            <div className={"multipliernum" + multiplierclassname}>{this.multiplierNum}</div>
            <div className={"letter selected" + letterStyle}>
              {value}
              <div className={"letterscore"}>
                {score}
              </div>
            </div>
          </div>
        </div>
      );
    }
    else{
      return(
        <div className={classname}>
          <div className={"letter" + letterStyle}>
            {value}
            <div className={"letterscore"}>
              {score}
            </div>
          </div>
        </div>
      );
    }
  }


}

export default App;
