const data = {
  en:{
    playerdisconnect:"{0} disconnected",
    playercancel:"{0} has cancelled the game!",
    dictloaded:"LOADED DICTIONARY",
    dictfailed:"LOAD FAILED",
    swapdeny:"You can't swap with less than 7 letters left in the pool!",
    swapmin:"You must select at least one letter to swap!",
    tempconnect:"Word must connect to already existing one!",
    tempcenter:"The first word must use the center square!",
    dualletters:"You must use the {0} letter instead of {1} and {2} separately!",
    invalidword:"{0} is an invalid word!",
    invalidwords:"{0} are invalid words!",
    squareinuse:"This square is already in use!",
    tempstraightline:"You may only place letters in one straight line!",
    tempconnected:"All placed letters must be connected!",
    maxplayers:"Sorry, the maximum amount of players is 5!",
    remaining:"Letters Remaining",
    drawwarning:"Draw between ",
    drawand:" and ",
    winwarning:"{0} wins!",
    noscorewarning:"There have been no points scored in the past {0} turns. The game will end after 6 consecutive turns of no scoring.",
    waiting:"Waiting for players...",
    playerstitle:"Players",
    dictloading:"Loading Dictionary for {0}",
    dictprocessing:"Processing Dictionary for {0}",
    lettertitle:"Letter",
    wordtitle:"Word",
    board:"Board"
  },
  de:{
    playerdisconnect:"{0} hat das Spiel verlassen",
    playercancel:"{0} hat das Spiel abgebrochen!",
    dictloaded:"WÖRTERBUCH GELADEN",
    dictfailed:"LADEN ABGEBROCHEN",
    swapdeny:"Tauschen nicht möglich, da weniger als 7 Buchstaben übrig sind.",
    swapmin:"Sie müssen mindestends einen Buchstaben zum Tauschen auswählen!",
    tempconnect:"Gespieltes Wort muss mit einem bereits bestehenden verbunden sein!",
    tempcenter:"Das erste Wort muss das mittlere Feld benutzen!",
    dualletters:"Sie müssen den {0} Buchstaben benutzen anstatt {1} und {2} separat.",
    invalidword:"{0} ist ein ungültiges Wort!",
    invalidwords:"{0} sind ungültige Wörter!",
    squareinuse:"Dieses Feld wird bereits benutzt!",
    tempstraightline:"Sie dürfen Buchstaben bloß in einer einzigen geraden Linie setzen!",
    tempconnected:"Alle gesetzen Buchstaben müssen miteinander verbunden sein!",
    maxplayers:"Sorry, die maximale Anzahl and Spielern ist 5!",
    remaining:"Verbleibende Buchstaben",
    drawwarning:"Unentschieden zwischen ",
    drawand:" und ",
    winwarning:"{0} gewinnt!",
    noscorewarning:"Es wurden in den letzten {0} Zügen keine Punkte erzielt. Das Spiel endet nach 6 aufeinander folgenden Zügen ohne Punkte.",
    waiting:"Es wird auf Spieler gewartet...",
    playerstitle:"Spieler",
    dictloading:"Lade Wörterbuch für {0}",
    dictprocessing:"Verarbeite Wörterbuch für {0}",
    lettertitle:"Buchst.",
    wordtitle:"Wort",
    board:"Spielbrett"
  },
  es:{
    playerdisconnect:"{0} desconectado",
    playercancel:"{0} ha cancelado el juego!",
    dictloaded:"DICCIONARIO CARGADO",
    dictfailed:"CARGA FALLIDA",
    swapdeny:"No se puede intercambiar con menos de 7 letras que quedan en la piscina!",
    swapmin:"Debe seleccionar al menos una letra para intercambiar!",
    tempconnect:"La palabra debe conectarse a una ya existente!",
    tempcenter:"La primera palabra debe usar el cuadrado central!",
    dualletters:"Debe utilizar la letra {0} en lugar de {1} y {2} por separado!",
    invalidword:"{0} es una palabra inválida!",
    invalidwords:"{0} son palabras inválidas!",
    squareinuse:"Esta plaza ya está en uso!",
    tempstraightline:"Sólo puede colocar las letras en una línea recta!",
    tempconnected:"Todas las letras colocadas deben estar conectadas!",
    maxplayers:"Lo sentimos, la cantidad máxima de jugadores es de 5!",
    remaining:"Cartas restantes",
    drawwarning:"Empate entre ",
    drawand:" y ",
    winwarning:"¡{0} gana!",
    noscorewarning:"No ha habido puntos anotados en los últimos {0} turnos. El juego terminará después de 6 turnos consecutivos de no anotar.",
    waiting:"Esperando a los jugadores...",
    playerstitle:"Jugadores",
    dictloading:"Cargando Diccionario para {0}",
    dictprocessing:"Diccionario de procesamiento para {0}",
    lettertitle:"Carta",
    wordtitle:"Palabra",
    board:"Tablero"
  },
  hr:{
    playerdisconnect:"{0} se odspojio",
    playercancel:"{0} je prekinuo igru!",
    dictloaded:"RIJEČNIK UČITAN",
    dictfailed:"UČITAVANJE NEUSPJELO",
    swapdeny:"Nemožete napraviti zamjenu sa manje od 7 riječi u ulogu!",
    swapmin:"Morate odabrati bar jedno slovo za zamjenu!",
    tempconnect:"Riječ mora biti spojena sa već postojećom!",
    tempcenter:"Prva riječ mora biti na centralnom polju!",
    dualletters:"Morate koristiti {0} slovo, a ne {1} i {2} odvojeno!",
    invalidword:"{0} nije ispravna riječ!",
    invalidwords:"{0} su neispravne riječi!",
    squareinuse:"Ovo polje se već koristi!",
    tempstraightline:"Slova možete postavljati samo u jednoj ravnoj liniji!",
    tempconnected:"Sva postavljena slova moraju biti spojena!",
    maxplayers:"Žao nam je, 5 je maksimalan broj igrača!",
    remaining:"Preostala slova",
    drawwarning:"Piši između ",
    drawand:" i ",
    winwarning:"{0} pobjeđuje!",
    noscorewarning:"Nije bilo bodova zadnjih {0} rundi. Igra će završiti nakon 6 rundi bez bodova.",
    waiting:"Čekanje na ostale igrače...",
    playerstitle:"Igrači",
    dictloading:"Učitavanje Riječnik za {0}",
    dictprocessing:"Procesuiram Riječnik za {0}",
    lettertitle:"Slovo",
    wordtitle:"Riječ",
    board:"Ploča"
  }
}
export {data};
